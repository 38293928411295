/* Cores */
$white: #fff;
$black: #000;

$gray: #777;
$blue: #337ab7;
$green: #5cb85c;
$blue-light: #5bc0de;
$yellow: #f0ad4e;
$orange: #f18a21;
$red: #d9534f;

$brown: #85685b;
$brown-light: #c8b59e;
$brown-lighter: #e9e0d7;
$brown-dark: #4f2c1e;

$purple: #775ca7;
$purple-dark: #753e9f;
$purple-darker: #513557;

$gold: #ad906e;

$pink: #ed19d7;
$pink-dark: #af538a;



/* Typography */

$font-bold: 'martellight';

/* Screen sizes */
$sm-screen: 768px;
$md-screen: 992px;
$lg-screen: 1200px;
$mdScreen: 991px;

$bp-large: 1200px;
$bp-medium: 991px;
$bp-small: 639px;