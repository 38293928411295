// Imports
@import "variables";

#news {
  // width: 200px;
  // margin-top: 20px;
  background: #fff;

  p {
    margin: 0;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 12px;
    color: $purple-dark;

    big {
      font-size: 17px;
    }
  }

  input {
    width: 170px;
    height: 27px;
    line-height: 19px;
    padding: 5px 10px;
    border: $purple-dark 2px solid;
    border-right: 0;
    background: #fff;
    float: left;
  }

  button {
    width: 29px;
    height: 27px;
    line-height: 27px;
    border: 0;
    background: $purple-dark;
    text-align: center;
    color: $white;
    float: left;
  }
}

.newsletter {
    position: fixed;
    top: 145px;
    left: -5px;
    background-color: $white;
    z-index: 100000;
    box-sizing: border-box;
    clear: both;
    color: $black;
    min-width: 258px;
    width: 400px;
    font-size: 14px;
    letter-spacing: 0;
    margin: 12px;
    box-shadow: 0 0 8px -2px rgba(0,0,0,0.3);
    border-radius: 6px;

    .wrapper {
      width: 100%;
      position: relative;
      float: left;
      box-sizing: border-box;
      padding: 6px;

      .close {
        position: absolute;
        right: 0;
        top: 8px;
      }

      .image {
        box-sizing: border-box;
        color: #000;
        float: left;
        padding-left: 30px;
        margin-top: 13px;

        img {
          max-width: 100px;
        }
      }

      .news-content {
        form {
          p {
            margin-bottom: 4px;

            &.cadastre {
              color: #000;
            }
          }
        }
      }
    }
  }

.destaque {
  color: $pink !important;
}

.ir {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.margin-bottom {
  margin-bottom: 60px;
}

.slider .slider-track {
  background: #d2c3b3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.slider .slider-track .slider-selection {
  background: #b7a288;
}
.slider .slider-handle {
  background: #af538a;
  border: #cbbcaa 1px solid;
}

#img-header {
  display: block;
}
#img-header .img {
  width: 100%;
  height: 496px;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}
#img-header .img.small {
  height: 256px;
}
#img-header .img .text-header {
  width: 50%;
  height: 290px;
  padding: 50px;
  display: table;
}
#img-header .img .text-header p {
  font-size: 60px;
  line-height: 60px;
  text-align: right;
  color: #fff;
  padding: 0 80px 0 20px;
  display: table-cell;
  vertical-align: middle;
}
#img-header .img .text-header p.purple {
  color: #775ca7;
}
#img-header .img .text-header p.text-left {
  text-align: left;
}
#img-header .img .text-header .corporativo-center,
#img-header .img .text-header .eventos-center {
  text-align: center;
}
#img-header .img .text-header .left-aling-title {
  text-align: left !important;
  padding-left: 46px;
}
#img-header .center-title-lojas {
  padding-top: 180px !important;
  width: 100% !important;
  text-align: center !important;
}
#img-header .center-title-lojas p {
  font-family: 'martellight';
  text-align: center !important;
  text-transform: uppercase;
}


#blocos {
  margin-top: -170px;
  max-height: 490px;
}
#blocos .left-blocos {
  padding: 30px 60px 30px 60px;
  background: #887164;
  height: 490px;
  max-height: 490px;
  text-align: left;
  z-index: 10;
}
#blocos .left-blocos.dark-brown {
  background: url("../img/dark-brown.jpg");
}
#blocos .left-blocos.purple-left {
  background: url("../img/purple-bg.jpg");
}
#blocos .left-blocos.purple-left-2 {
  background: url("../img/purple-left-2.png");
}
#blocos .left-blocos.pink-left {
  background: url("../img/pink-left.jpg");
}
#blocos .left-blocos.cinza {
  background: url("../img/cinza.png");
  max-height: 590px !important;
}
#blocos .left-blocos.cinza a {
  background: #5c504a;
  color: #fff;
  height: auto;
  width: 198px;
}
#blocos .left-blocos.cinza .seta-get {
  margin-top: 130px;
  background: none;
  width: auto;
}
#blocos .left-blocos.cinza .seta-get .colheita {
  width: 240px;
}
#blocos .left-blocos.sabores-left {
  background: url("../img/sabores-left.jpg");
}
#blocos .left-blocos.sabores-left h3 {
  width: 380px;
  font-family: 'martellight';
}
#blocos .left-blocos.sabores-left p {
  font-family: 'martellight';
}
#blocos .left-blocos.sabores-left a {
  margin-top: 60px;
  position: relative;
  cursor: pointer;
}
#blocos .left-blocos.sabores-left a p {
  width: 245px;
  color: #331f56;
}
#blocos .left-blocos h3 {
  color: #fff;
  font-family: 'martellight';
  width: 390px;
  line-height: 35px;
  margin-bottom: 30px;
}
#blocos .left-blocos h3.beige {
  color: #c8b59e;
}
#blocos .left-blocos p {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 18px;
  color: #fff;
  line-height: 26px;
}
#blocos .left-blocos ul {
  padding: 0;
  margin: 0;
}
#blocos .left-blocos ul li {
  margin: 10px 0;
  padding-left: 15px;
  list-style: none;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #c8b59e;
  position: relative;
}
#blocos .left-blocos ul li:after {
  width: 10px;
  height: 1px;
  background: #c8b59e;
  content: '';
  bottom: 8px;
  left: 0;
  position: absolute;
}
#blocos .left-blocos ul li a {
  width: auto;
  height: auto;
  margin: 10px 0;
  display: inline;
  color: #c8b59e;
}
#blocos .left-blocos ul.list {
  margin: 20px 0 0 25px;
  position: relative;
}
#blocos .left-blocos ul.list:before {
  width: 1px;
  height: 56px;
  background: #fff;
  content: '';
  top: 5px;
  left: 0;
  position: absolute;
}
#blocos .left-blocos ul.list li {
  width: 50%;
  padding: 5px 20px;
  margin: 0;
  float: left;
  color: #fff;
}
#blocos .left-blocos ul.list li:after {
  display: none;
}
#blocos .left-blocos ul.list li a {
  color: #fff !important;
}
#blocos .left-blocos a {
  width: 300px;
  height: 56px;
  margin: 40px 0 0 0px;
  display: block;
  text-decoration: none;
}
#blocos .left-blocos a span {
  background: url("../img/arrow-down.png");
  width: 13px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  margin: -10px 5px 0 0;
}
#blocos .left-blocos a .chocolate-seta {
  background: url("../img/arrow-down.png") center center;
  background-repeat: no-repeat;
  background-color: #462448;
  width: 40px;
  height: 61px;
  position: relative;
}
#blocos .left-blocos a .chocolate-seta:after {
  position: absolute;
  content: ' ';
  height: 50px;
  width: 1px;
  background-color: #fff;
  right: 6px;
  top: 5px;
}
#blocos .left-blocos a .colheita {
  width: 250px;
  font-family: 'martellight';
  font-weight: normal;
  font-size: 18px;
  color: #fff;
  padding: 10px 0 0 20px;
  line-height: 24px !important;
  border-left: 1px solid #fff;
  display: inline-block;
  vertical-align: middle;
}
#blocos .left-blocos.purple-left a .colheita {
  color: #f65eff;
}
#blocos .left-blocos a .colheita-white {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}
#blocos .left-blocos a .no-border {
  border: 0;
  padding-left: 0;
}

#blocos .right-blocos {
  padding:0;
  height:490px;
  max-height:490px;
  text-align:center;
  position:relative;
  z-index:10;
}

#blocos .left-blocos img {
  width: 540px;
  height: 490px;
  text-align: center;
}
#blocos .left-blocos .text {
  width: 100%;
  height: 100%;
  padding: 60px;
  top: 0;
  left: 0;
  position: absolute;
}
#blocos .left-blocos .text p {
  color: #4a3858;
  font-family: 'martelbold';
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
#blocos .left-blocos.sabores-right .text p {
  color: #fff !important;
  margin-top: 100px;
}
#blocos .left-blocos .text h3 {
  color: #9e6b56;
  font-family: 'martelbold';
  font-size: 30px;
  text-align: center;
}
#blocos .left-blocos.sabores-right .text h3 {
  color: #fff;
}
#blocos .left-blocos .text .truffes-title {
  color: #493756;
}
#blocos .left-blocos .text .white {
  color: #fff;
}
#blocos .left-blocos .text a.link {
  text-decoration: none;
  color: #5b3e32;
  font-size: 14px;
  font-family: 'martelbold';
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 55px;
}
#blocos .left-blocos .text a.btn {
  margin-top: 30px;
}
#blocos .left-blocos .text .saiba-mais-trufas {
  border: 0;
  background: transparent;
  font-size: 20px;
  color: #493756;
  padding: 0;
  margin-top: -10px !important;
}
#blocos .left-blocos .text .saiba-mais-trufas:hover {
  background: transparent !important;
}

#voltar {
  width: 210px;
  height: 45px;
  display: block;
  margin: auto;
  margin-top: 50px;
  border-color: #fff;
  color: #000;
  line-height: 37px;
  font-size: 12px;
  background-color: #fff;
}
#voltar:before {
  width: 40px;
  height: 32px;
  content: '';
  float: left;
  margin-left: -15px;
  background: url("../img/voltar-seta.png") center top;
  border-right: solid 1px;
  border-color: #c7b699;
}

#mais-vendidos .sell-more,
#refinar-busca .sell-more {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#mais-vendidos .sell-more .btn,
#refinar-busca .sell-more .btn,
#mais-vendidos .sell-more .btn-default,
#refinar-busca .sell-more .btn-default {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #af538a;
  border-color: #ad906e;
}
#mais-vendidos .sell-more span,
#refinar-busca .sell-more span {
  margin-left: 10px;
  color: #c7b299;
}
#mais-vendidos .mais-vendidos-menu,
#refinar-busca .mais-vendidos-menu {
  min-width: 160px;
  margin-top: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#mais-vendidos .mais-vendidos-menu li,
#refinar-busca .mais-vendidos-menu li {
  margin: 3px;
}
#mais-vendidos .mais-vendidos-menu li a,
#refinar-busca .mais-vendidos-menu li a {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #c7b299;
  text-decoration: underline;
}
#mais-vendidos .mais-vendidos-menu li a:hover,
#refinar-busca .mais-vendidos-menu li a:hover {
  color: #60564a;
  background: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#refinar-busca .sell-more .btn {
  border: 0;
  padding: 0 20px;
}
#refinar-busca .sell-more .btn:hover {
  background: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#refinar-busca .sell-more .btn span {
  font-size: 16px;
  margin-right: 7px;
  color: #937db9;
  margin-left: 0;
}
#refinar-busca .refinar-busca-menu {
  margin-top: 0px;
  padding-bottom: 10px;
  left: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#refinar-busca .refinar-busca-menu .price .slider {
  max-width: 120px;
  margin: 0 5px;
}
#refinar-busca .refinar-busca-menu .produtos,
#refinar-busca .refinar-busca-menu .sabores,
#refinar-busca .refinar-busca-menu .price {
  margin: 0 20px;
}
#refinar-busca .refinar-busca-menu .produtos p,
#refinar-busca .refinar-busca-menu .sabores p,
#refinar-busca .refinar-busca-menu .price p {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 0;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #af538a;
}
#refinar-busca .refinar-busca-menu .produtos ul,
#refinar-busca .refinar-busca-menu .sabores ul,
#refinar-busca .refinar-busca-menu .price ul {
  padding: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
#refinar-busca .refinar-busca-menu .produtos ul li,
#refinar-busca .refinar-busca-menu .sabores ul li,
#refinar-busca .refinar-busca-menu .price ul li {
  list-style: none;
  font-size: 12px;
  padding: 5px;
  margin: 2px 0 0 0;
}
#refinar-busca .refinar-busca-menu .produtos ul li.active,
#refinar-busca .refinar-busca-menu .sabores ul li.active,
#refinar-busca .refinar-busca-menu .price ul li.active {
  background: #efe9e5;
}
#refinar-busca .refinar-busca-menu .produtos ul li a,
#refinar-busca .refinar-busca-menu .sabores ul li a,
#refinar-busca .refinar-busca-menu .price ul li a {
  color: #4f2c1e;
}
#refinar-busca .refinar-busca-menu .price .form-group b {
  margin: 0 5px 0 5px;
}
#refinar-busca .busca-resultado {
  text-decoration: none;
  display: block;
  width: 200px;
  margin: auto;
  text-align: center;
}
#refinar-busca .busca-resultado p {
  width: auto;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  position: relative;
  margin: 0 0 5px 0;
}
#refinar-busca .busca-resultado p:after {
  position: absolute;
  content: ' ';
  right: 0px;
  height: 13px;
  width: 13px;
  background: url("../img/close-product.png");
}
.less {
  width: 13px;
  height: 13px;
  background: #000;
  margin-left: -13px;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  p {
    padding-left: 15px;
    margin: -2px 0 0px 0px !important;
  }
}
.less:after {
  position: absolute;
  content: ' ';
  width: 7px;
  height: 1px;
  background: #fff;
  top: 6px;
  left: 3px;
}
.align-arrow {
  position: relative;
}
.vertical {
  width: 100%;
  height: 100%;
  display: table;
}
.vertical .middle {
  display: table-cell;
  vertical-align: middle;
}
#produtos-topo {
  width: 100%;
  height: 458px;
  background: #e9e0d7;
  text-align: center;
}

.center-block p {
  text-align: center !important;
  text-transform: uppercase;
}
.center-block p.purple {
  font-size: 48px !important;
}


.popup {
  display: none;
}
.popup .popup-backdrop,
.popup .popup-content,
.popup .popup-title,
.popup .popup-descr,
.popup .popup-form {
  opacity: 0;
}
.popup,
.popup-backdrop {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000000;
}
.popup-backdrop {
  background-color: #000;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}
.popup-content {
  top: 50%;
  left: 50%;
  width: 538px;
  height: 405px;
  position: absolute;
  text-align: center;
  z-index: 10000000;
  background: #fff url("../img/popups/popup-newsletter.jpg") no-repeat;
  background-size: cover;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.popup-content:before,
.popup-content:after {
  display: table;
  content: "";
}
.popup-content:after {
  clear: both;
}
.popup-content .img-mobile {
  display: none;
}
.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  z-index: 100;
  cursor: pointer;
  background: url("../img/popups/close.png") no-repeat;
}
.popup-wrapper {
  width: 245px;
  float: right;
  margin-right: 25px;
  padding-top: 70px;
}
.popup-title {
  margin: 0 0 25px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 24px;
  color: #514173;
}
.popup-descr {
  line-height: 1.4em;
  margin: 0 0 30px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  -moz-transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 16px;
  color: #9d6b37;
}
.popup-form {
  background: none;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transition-delay: 1s;
  -o-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -webkit-transition-delay: 1s;
}
.popup-form input::-webkit-input-placeholder {
  color: #514173;
}
.popup-form input::-moz-placeholder {
  opacity: 1 !important;
  color: #514173;
}
.popup-form input::-ms-input-placeholder {
  color: #514173;
}
.popup-form input::-o-input-placeholder {
  color: #514173;
}
.popup-form input[type="email"] {
  width: 100%;
  height: 35px;
  display: block;
  padding: 0 10px;
  margin: 0 auto 20px;
  text-align: center;
  border: 2px solid #3f2f60;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 16px;
  color: #514173;
}
.popup-form input[type="email"].error {
  border-color: #a94442;
}
.popup-form button {
  width: 125px;
  height: 35px;
  display: block;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  background-color: #3f2f60;
  border: none;
  outline: none;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 14px;
  color: #fff;
}
.popup-form button:hover {
  background-color: #775ca7;
}
.popup-msg-error {
  display: block;
  text-align: center;
  margin-top: 10px;
}
.popup-msg-error label {
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 14px;
  color: #a94442;
}
.popup-msg-success {
  width: 155px;
  right: 55px;
  position: absolute;
  top: 50%;
  display: none;
  background-color: #fff;
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.popup-msg-success p {
  line-height: 1.3em;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 20px;
  color: #9d6b37;
}
.popup-active .popup-content,
.popup-active .popup-title,
.popup-active .popup-descr,
.popup-active .popup-form {
  opacity: 1;
}
.popup-active .popup-backdrop {
  opacity: 0.7;
}








.product-list {
  padding: 0;
  margin: 0;
  text-align: left;
}
.product-list li {
  width: 355px;
  margin: 15px 0px;
  list-style: none;
  background: #f5f3f0;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  position: relative;

  span.esgotado {
    bottom: 0;
    width: 100%;
    right: 0;
    background: $purple;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    position: absolute;
  }
}
.product-list li:nth-child(3n+2) {
  margin: 15px 3px;
}
.product-list li .heart {
  z-index: 2;
  float: right;
  margin: 10px 10px 0 0;
  font-size: 14px;
  color: #898989;
  text-decoration: none;
}
.product-list li .heart.selected {
  color: #d7ad5f;
}
.product-list li .link {
  display: block;
  text-decoration: none;

  .esgotado {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
}
.product-list li .link h3 {
  margin: 0;
  padding: 0 20px;
  padding-top: 40px;
  text-align: center;
}
.product-list li .link p {
  padding: 5px 20px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #898989;
  text-align: center;
}
.product-list li .link span {
  display: block;
  padding: 5px 20px;
  margin-bottom: 0px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 20px;
  color: #000;
}
.product-list li .link span small {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #000;
  margin-right: 4px;
}
.product-list li .link .img {
  height: auto !important;
  position: relative;
}
.product-list li:hover .img {
  background: #826a62;
}
.product-list li:hover .img img {
  opacity: 0.4;
}
.product-list li:hover .img:after {
  position: absolute;
  content: ' ';
  height: 39px;
  width: 39px;
  background: url("../img/more-hover.png");
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
}
.product-list li:hover h3:after {
  color: #ad906e;
}
.product-list li:hover span {
  color: #ad906e;
}
.product-list li:hover p {
  color: #888;
}
.product-list li:hover span small {
  color: #ad906e;
}
.product-list li.custom .link {
  background: #e9d4df !important;
  position: relative;
}
.product-list li.custom .link h3 {
  color: #775ca7 !important;
}
.product-list li.custom .link .escolha {
  position: absolute;
  background: #e9d4df;
  color: #775ca7;
  font-size: 12px;
  padding: 5px 17px;
  text-transform: uppercase;
  width: 176px !important;
  left: 50%;
  margin-left: -88px;
  z-index: 1000;
}
.product-list li.custom .link .escolha:after {
  position: absolute;
  content: ' ';
  width: 100px;
  height: 1px;
  background: #775ca7;
  top: 0;
  left: 50%;
  margin-left: -50px;
}
.product-list.mais-vendidos li {
  background-color: #e9d4df;
}
.product-list.mais-vendidos li h3 {
  color: #775ca7;
}
.product-list.mais-vendidos li .link {
  position: relative;
}
.product-list.mais-vendidos li .sabores {
  width: 177px;
  height: 25px;
  margin: auto;
  font-size: 12px;
  color: #775ca7;
  background-color: #e9d4df;
  position: absolute;
  left: 90px;
}
.product-list.mais-vendidos li .sabores:before {
  content: '';
  width: 95px;
  height: 2px;
  background-color: #775ca7;
  position: absolute;
  top: -2px;
  left: 22%;
}

#identificacao {
  background: #f1e8e0;
  height: 510px;
}
#identificacao .center-identification {
  width: 1066px;
  margin: auto;
}
#identificacao .center-identification .left-indentification,
#identificacao .center-identification .middle-indentification,
#identificacao .center-identification .right-indentification {
  padding: 100px 50px 0 50px;
}
#identificacao .center-identification .left-indentification .title,
#identificacao .center-identification .middle-indentification .title,
#identificacao .center-identification .right-indentification .title {
  text-align: center;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 20px;
  color: $brown;
  margin-bottom: 35px;
}
#identificacao .center-identification .left-indentification {
  text-align: center;
}
#identificacao .center-identification .left-indentification .form-group {
  text-align: center;
  padding: 0 20px;
}
#identificacao .center-identification .left-indentification .form-group div {
  margin: 20px 0;
}
#identificacao .center-identification .left-indentification .form-group div label {
  display: block;
  text-align: center;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #85685b;
}
#identificacao .center-identification .left-indentification .form-group div input {
  width: 100%;
  background: #fff;
  border: none;
  height: 38px;
  line-height: 36px;
  padding: 2px 10px;
}
#identificacao .center-identification .left-indentification a {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #775ca7;
  padding: 0;
  margin: 0;
}
#identificacao .center-identification .middle-indentification {
  height: 510px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
#identificacao .center-identification .middle-indentification .text {
  text-align: center;
  margin: 0 25px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #85685b;
}
#identificacao .center-identification .middle-indentification a {
  display: block;
  text-align: center;
  margin: 20px 30px 0 30px;
}
#identificacao .center-identification .right-indentification .text {
  text-align: center;
  margin: 0 25px 20px 25px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #85685b;
}
#identificacao .center-identification .right-indentification .fb {
  text-align: center;
  margin-bottom: 10px;
}
#identificacao .center-identification .right-indentification .fb a {
  width: 179px;
  height: 37px;
  margin: auto;
  display: block;
  background: url("../img/facebook-entrar.png");
}
#identificacao .center-identification .right-indentification .ob {
  text-align: center;
}
#identificacao .center-identification .right-indentification .ob a {
  width: 179px;
  height: 37px;
  margin: auto;
  display: block;
  background: url("../img/entrar-onebuy.png");
}

#loja-online {
  background: #f1e8e0;
  padding: 30px 0 55px 0;
}
#loja-online .nav-tabs {
  text-align: left;
}
#loja-online .table-loja-online {
  // width: 830px;
  margin: auto;
  overflow: hidden;
}
#loja-online .table-loja-online .table-left {
  border: 0;
  margin-top: 30px;
}
#loja-online .table-loja-online .table-left .title-blue {
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 20px;
  color: #4f198c;
}
#loja-online .table-loja-online .table-left li.active {
  background: #f1e8e0;
}
#loja-online .table-loja-online .table-left li.active a {
  background: #f1e8e0;
  color: #d7ad5f;
  position: relative;
}
#loja-online .table-loja-online .table-left li.active a:before {
  background: #d7ad5f;
}
#loja-online .table-loja-online .table-left li a {
  text-transform: capitalize;
  display: block;
  padding: 5px 20px;
  line-height: 16px;
  height: auto;
  border: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  position: relative;
}
#loja-online .table-loja-online .table-left li a:before {
  position: absolute;
  content: ' ';
  height: 1px;
  width: 15px;
  background: #4f2c1e;
  bottom: 10px;
  left: 0;
}
#loja-online .table-loja-online #como-comprar .sub-title {
  text-transform: uppercase;
  font-family: 'martelheavy';
  margin-bottom: 5px;
}
#loja-online .table-loja-online #como-comprar span {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
  line-height: 16px;
  font-style: italic;
}
#loja-online .table-loja-online #entrega .title {
  font-family: 'martelheavy';
  margin-bottom: 5px;
}
#loja-online .table-loja-online #como-comprar,
#loja-online .table-loja-online #entrega,
#loja-online .table-loja-online #termo-de-uso,
#loja-online .table-loja-online #seguranca
#loja-online .table-loja-online #privacidade {
  padding-left: 60px;
  margin-top: 22px;
  border-left: 1px solid #fff;
}
#loja-online .table-loja-online #como-comprar h3,
#loja-online .table-loja-online #entrega h3,
#loja-online .table-loja-online #termo-de-uso h3,
#loja-online .table-loja-online #seguranca h3
#loja-online .table-loja-online #privacidade h3 {
  margin-top: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 30px;
  color: #4f2c1e;
}
#loja-online .table-loja-online #como-comprar p,
#loja-online .table-loja-online #entrega p,
#loja-online .table-loja-online #termo-de-uso p,
#loja-online .table-loja-online #seguranca p
#loja-online .table-loja-online #privacidade p {
  line-height: 22px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
  margin-bottom: 20px;
}
#loja-online .table-loja-online #como-comprar p a,
#loja-online .table-loja-online #entrega p a,
#loja-online .table-loja-online #termo-de-uso p a,
#loja-online .table-loja-online #seguranca p a
#loja-online .table-loja-online #privacidade p a {
  color: #513593;
  text-decoration: underline;
}
#modal-entrega .form-group {
  width: 400px;
  margin: auto;
}
#modal-entrega ul {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
#modal-entrega ul li {
  list-style: none;
  margin: 5px 0;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#modal-entrega ul li:first-child {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
  text-transform: uppercase;
}
.request-by-phone {
  top: 0;
  left: 0;
  width: 100%;
  color: #fff !important;
  z-index: 900;
  font-size: 16px;
  position: fixed;
  background-color: #4b366f;
  transition: top 0.5s, opacity 0.3s ease-in-out;
}
.nav-up {
  top: -50px;
  opacity: 0;
}
.request-by-phone a {
  color: #fff !important;
  text-decoration: none;
}
.request-container {
  width: 650px;
  // height: 50px;
  margin: 0 auto;
  line-height: 59px;
  padding-left: 40px;
  text-align: center;
}
.request-container .purple {
  color: #8d7baf !important;
}
.active-by-phone.active {
  padding-top: 120px !important;
}
.active-navbar-phone {
  top: 50px !important;
}
.active-navbar-phone #menu > li .submenu {
  top: 126px !important;
}
.active-navbar-phone #menu-mobile {
  top: 140px !important;
  height: calc(100% - 160px) !important;
}
.img-fade .item {
  opacity: 0.6;
}
.img-fade .item.active {
  opacity: 1;
}

.indicators-left {
  margin-left: 4px;
}
.indicators-left .carousel-inner {
  float: right;
}
.indicators-left .carousel-indicators {
  left: 0 !important;
  margin-left: 0;
}
.comprar-produtos {
  width: 134px;
  font-size: 14px;
}

.view-letras .title.pink {
  color: #af538a !important;
}
.image-main-letras {
  width: 625px;
  display: inline-block;
}
.image-main-letras img {
  width: 100%;
  display: block;
}

.img-header-linha-produtos img {
  width: 100%;
}


#formBusca2 div.mobile-search {
  margin: 10px;
}
.mobile-search .input-group-addon {
  background-color: #4b366f;
  color: #fff;
  border-color: #4b366f;
}
.banner-blackfriday .img {
  background-image: url("../img/blackfriday/banner-desktop.jpg");
}
.product-list.related-products .wrapper-price {
  position: relative;
}
.product-list.related-products .final-price {
  color: #ed19d7 !important;
}
.product-list.related-products .final-price small {
  color: #ed19d7 !important;
}
.product-list.related-products .discount-price {
  color: #000 !important;
  display: inline-block !important;
  text-align: center;
  padding: 0 !important;
  margin-bottom: 0 !important;
  position: absolute;
  top: -12px;
  left: 50%;
  font-size: 14px !important;
  line-height: 1.5em !important;
  width: auto !important;
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.product-list.related-products .discount-price small {
  color: #000 !important;
  font-size: 14px !important;
}
.product-list.related-products .discount-price:before {
  top: 10px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #4f2c1e;
  position: absolute;
  z-index: 5;
}

#menu-sobre {
  width: 100%;
  height: 58px;
  border-bottom: #d7ad5f 1px solid;
  padding: 0;
  margin: 0;
  background: #fff;
  text-align: center;
  top: 90px;
  left: 0;
  position: absolute;
}
#menu-sobre li {
  height: 58px;
  line-height: 58px;
  list-style: none;
  display: inline-block;
  vertical-align: top;
}
#menu-sobre li a {
  height: 58px;
  line-height: 58px;
  padding: 0 15px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #000;
  letter-spacing: 1px;
  text-decoration: none;
  display: block;
}
#menu-sobre li.active a {
  border-bottom: #d7ad5f 3px solid;
  color: #d7ad5f;
}

ul.images {
  margin: 0;
  padding: 0;
}
ul.images li {
  width: 14.28%;
  list-style: none;
  float: left;
}
ul.images li:first-child {
  margin: 0;
}
ul.images li img {
  max-width: 100%;
}
ul.list-awards {
  padding-top: 30px;
  text-align: center;
}
ul.list-awards li {
  width: auto;
  float: none;
  display: inline-block;
}
ul.list-awards li img {
  width: auto;
  height: 95px;
}

.filter-align {
  display: flex;
  justify-content: space-around;
}

@media (min-width: 1247px) and (max-width: 1360px) {
  .letter,
  .product {
    width: 380px !important;
  }
  .amount {
    width: 216px !important;
  }
  .alert-letters {
    width: 156px;
  }
}

@media (min-width: $mdScreen) {
  #refinar-busca {
    text-align: center;

    .refinar-busca-menu {
      width: 180%;
    }
  }
}